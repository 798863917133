import React from 'react';
import Markup from 'components/Markup';
import Table from 'landing/components';
import * as styles from './Article.module.scss';
import cn from 'classnames';

export default function Article({
  title,
  textBlocks,
  table,
  firstBlock,
  isDescription,
  withCheckMark,
}) {
  return (
    <div
      key={title}
      className={cn(
        styles.articlesWrapper,
        isDescription && styles.description,
        withCheckMark && styles.withCheckMark,
      )}
    >
      <div className={styles.article}>
        {title && (
          <Markup tag="h3" className={cn(styles.title, firstBlock && styles.firstBlock)}>
            {title}
          </Markup>
        )}
        {textBlocks?.map((textBlock, i) => (
          <Markup key={i}>{textBlock}</Markup>
        ))}
      </div>
      <div className={styles.tableWrapper}>
        {table && <Table className={styles.table} {...table} />}
      </div>
    </div>
  );
}
